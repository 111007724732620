<style>
    .poptipContent .ivu-poptip-rel{
        width: 100%;
    }
    .stationFormItem .ivu-form-item-label:before {
        content: '*';
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        color: #ed4014;
    }
</style>
<template>
    <Layout>
        <Header class="header">
            <Breadcrumb class="breadcrumb">
                <BreadcrumbItem to="/sys_manage/threshold">阈值预警设置</BreadcrumbItem>
                <BreadcrumbItem>修改</BreadcrumbItem>
            </Breadcrumb>
        </Header>
        <Content class="content">
            <GeminiScrollbar style="height:100%;overflow: auto;">
            <Form ref="dataForm" :model="formData" :rules="rules" :label-width="200" >
                <FormItem v-if="thrType === 1 || thrType === 2" class="formItemWidth" label="阈值类型" prop="thrTypeText">
                    <Input v-model="formData.thrTypeText" readonly disabled/>
                </FormItem>
                <!-- formData.thrTypeText === '预报数据' -->
                <!-- thrType -->
                <FormItem v-if="thrType === 2" class="formItemWidth" label="预报时间" prop="cronStr">
                    <Input v-model="formData.cronStr" readonly disabled/>
                </FormItem>
                <FormItem class="formItemWidth stationFormItem" label='站点选择'>
                    <!-- <template>
                        <Poptip class="poptipContent" placement="bottom-start">
                            <Input class="poptipInput" v-model="stationList" type="textarea"/>
                            <div class="api" slot="content">
                                <stationSelect @checkedStation='checkedStation' v-if="stationArray.length>0" :areaList='stationArray' :stationIds='formData.stats'></stationSelect>
                            </div>
                        </Poptip>
                    </template> -->

                    <!-- <Input v-if="formData.thrTypeText === '预报数据'" v-model="stationList" type="textarea" disabled/>
                    <Input v-else v-model="stationList" type="textarea" @on-focus="openStation()"/> -->

                    <div v-if="thrType === 1" style="padding: 0 7px;min-height: 30px;width: 450px;border: 1px solid #0077EE;border-radius: 4px;color: #0077EE;" @click="openStation()">
                        {{stationList}}
                    </div>
                    <div v-else style="padding: 0 7px;min-height: 30px;width: 450px;border: 1px solid #0077EE;border-radius: 4px;color: #0077EE;" disabled>
                        {{stationList}}
                    </div>
                    <stationSelected v-if="showStationSelected && stationArray.length>0" @checkedStation='checkedStation' :showStationSelected='showStationSelected' :areaList='stationArray' :stationIds='formData.stats'></stationSelected>
                </FormItem>
                <FormItem class="formItemWidth" label="站点编号" prop="statsId">
                    <!-- <Input v-model="formData.stats" type="textarea" disabled/> -->
                    <div style="padding: 0 7px;min-height: 30px;width: 450px;border: 1px solid #0077EE;border-radius: 4px;color: #0077EE;" disabled>
                        {{formData.statsId}}
                    </div>
                </FormItem>
                <div class="typeAll ivu-form-item" v-if="thrType === 2 || thrType === 3">
                    <FormItem prop="element" style="margin:0 10px 0 20px;">
                        <Select v-model="formData.element" style="width: 170px;" @on-change="changeElement(formData.element)" >
                            <Option v-for="(item, index) in elementList" :key="index" :value="item.name">{{item.text}}</Option>
                        </Select>
                    </FormItem>
                    <FormItem prop="minValue" v-if="!hideMinInput">
                        <Input type="number" v-model="formData.minValue" style="width: 200px;" disabled/>
                    </FormItem>
                    <div v-if="!hideMinInput" style="margin:0 24px;">-</div>
                    <FormItem prop="maxValue">
                        <Input type="number" v-model="formData.maxValue" style="width: 200px" disabled/>
                    </FormItem>
                </div>
                <div class="typeAll ivu-form-item" v-else>
                    <FormItem prop="element" style="margin:0 10px 0 20px;">
                        <Select v-model="formData.element" style="width: 170px;" @on-change="changeElement(formData.element)" >
                            <Option v-for="(item, index) in elementList" :key="index" :value="item.name">{{item.text}}</Option>
                        </Select>
                    </FormItem>
                    <FormItem prop="minValue" v-if="!hideMinInput">
                        <Input type="number" v-model="formData.minValue" style="width: 200px;"/>
                    </FormItem>
                    <div v-if="!hideMinInput" style="margin:0 24px;">-</div>
                    <FormItem prop="maxValue">
                        <Input type="number" v-model="formData.maxValue" style="width: 200px"/>
                    </FormItem>
                </div>
                <FormItem class="formItemWidth" label="预警内容">
                    <Input v-if="thrType === 3" v-model="formData.content" type="textarea" disabled/>
                    <Input v-else v-model="formData.content" type="textarea"/>
                </FormItem>
                <FormItem label="人员选择">
                    <!-- <Input v-model="formData.personIds" type="textarea" style="display:none;"/> -->
                    <div>
                        <memberSelect @checkedMembers='checkedMembers' v-if="areaList.length>0 && roleList.length>0" :roleList='roleList' :areaList='areaList' :checkedMemberData='checkedMember'></memberSelect>
                    </div>
                </FormItem>
                <FormItem label="是否生效">
                    <RadioGroup v-model="formData.state" style="color: #0077EE;">
                        <Radio label="1">是</Radio>
                        <Radio label="0">否</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem>
                    <Button style="background: #00A0E9;" type="primary" @click="submit">保存</Button>
                </FormItem>  
            </Form>  
            </GeminiScrollbar> 
        </Content>
    </Layout>
</template>

<script>
import Vue from 'vue'
import request from '@/utils/request'
import stationSelect from './station_select.vue'
import stationSelected from './station_selected.vue'
import memberSelect from './member_select.vue'
import config from '@/utils/config'
export default Vue.extend({
    components: {
        stationSelect,
        stationSelected,
        memberSelect,
    },
    data() {
        return {
            id: null,
            thrType: -1,
            formData: {},
            stationList: '',
            elementList: [],
            areaList: [],
            roleList: [],
            rules: {
                stationList: [{ required: true, message: '站点不能为空', trigger: 'blur' }],
                statsId: [{ required: true, message: '站点不能为空', trigger: 'blur' }],
                content: [{ required: true, message: '预警内容不能为空', trigger: 'blur' }],
                element: [{ required: true, message: '请选择要素', trigger: 'change' }],
            },
            checkedMember: [],
            stationArray: [],
            hideMinInput: false,
            showStationSelected: false,
            // disabled: false,
            thrTypeText: '',
        }
    },
    created() {
        this.id = this.$route.params.id
        this.thrType = Number(this.$route.query.thrType)
        // console.log(this.$route.params)
        // console.log(this.$route.query)
        Promise.all([
            this.getElementList(),
            this.getAreaList(),
            this.getPublishRole(),
            this.getCheckedMember(),
            this.getStationList(),
        ]).then((e) => {
            this.getData()
        })
    },
    methods: {
        getElementList() {
            return request.get('/api/respond/element/list', null)
              .then((data) => {
                 this.elementList = data
              })
        },
        getAreaList() {
            return request.get('/api/sys/area/descendants', null)
            .then((data) => {
                const {current} = this.$store.state
                this.areaList = [...[{id: current.areaId, name: current.areaName}], ...data]
            })
        },
        getAreaName(value) {
            const current =  this.areaList.filter((e) => {
                if (e.id === value) {
                    return e
                }
            })
            return current[0].name
        },
        getStationList() {
            const params = {
                staLevels: '011,012,013,014',
            }
            // if (this.$store.state.current.areaId === config.defaultStationObj.adminCode) {
            //     params = {
            //         staLevels: '011,012,013',
            //     }
            // }
            return request.get('/cw/station_info/list', params)
            .then((data) => {
                this.stationArray = data
            })
        },
        getStationName(value) {
            const current =  this.stationArray.filter((e) => {
                if (e.station_id_c === value) {
                    return e
                }
            })
            return current[0].station_name
        },
        getPublishRole() {
            return request.get('/api/respond/respond_role/list', null)
            .then((data) => {
                this.roleList = data
            })
        },
        getData() {
            request.get(`/api/respond/threshold/${this.id}`)
            .then((data) => {
                // console.log(data)
                this.formData = data
                this.formData.statsId = data.stats.split(',').join('，')
                const stationIds = data.stats.split(',')
                const stationNames = []
                stationIds.forEach((element) => {
                    stationNames.push(this.getStationName(element))
                })
                this.stationList = stationNames.join('，')
                this.formData.state = this.formData.state + ''
                this.changeElement(this.formData.element)
                // console.log(this.elementList)
                // console.log(this.formData.element)
                const elements =  this.elementList.filter((e) => {
                if (e.name === this.formData.element) {
                        return e
                    }
                })
                this.elementList = elements
                if (data.thrType === 1) {
                    this.formData.thrTypeText = '实况数据'
                } else if (data.thrType === 2) {
                    this.formData.thrTypeText = '预报数据'
                    // console.log(data.beginMonDay)
                    // console.log(data.endMonDay)
                    const valStr = String(data.beginMonDay)
                    let month = ''
                    let day = ''
                    if (valStr.length === 3) {
                        month = `${Number(valStr.substring(0, 1))}`
                        day = Number(valStr.substring(1, 3))
                    }
                    if (valStr.length === 4) {
                        month = `${Number(valStr.substring(0, 2))}`
                        day = Number(valStr.substring(2, 4))
                    }
                    if (day < 11) {
                        this.formData.cronStr = `${month}月上旬`
                    } else if (day > 10 && day < 21) {
                        this.formData.cronStr = `${month}月中旬`
                    } else {
                        this.formData.cronStr = `${month}月下旬`
                    }
                }
            })
        },
        getCheckedMember() {
            return request.get(`/api/respond/respond_person/by_threshold_id/${this.id}`)
            .then((data) => {
                this.checkedMember = data
            })
        },
        submit() {
            // const params = this.formData
            const {element, ...params} = this.formData
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    request.put(`/api/respond/threshold/${this.id}`, params)
                    .then((res) => {
                        this.$Message.success('修改成功!')
                        this.$router.replace('/sys_manage/threshold')
                    }).catch((error) => {
                        this.$Message.warning(error)
                    })
                }
            })
        },
        openStation() {
            // console.log('站点编号')
            this.showStationSelected = true
        },
        checkedStation(value) {
            const {checked, status} = value
            this.showStationSelected = false
            if (status === 'ok') {
                this.formData.stats = checked.map((e) => e.station_id_c).join(',')
                this.formData.statsId = checked.map((e) => e.station_id_c).join('，')
                this.stationList = checked.map((e) => e.station_name).join(',')
            }
        },
        checkedMembers(value) {
            this.formData.personIds = value.map((e) => e.id)
        },
        changeElement(element) {
            if (element.indexOf('pre') > -1) {
                this.hideMinInput = true
            } else {
                this.hideMinInput = false
            }
        },
    },
})
</script>

<style scoped>
.formItemWidth {
    width: 650px;
}
.typeAll{
    display: flex;
}
.poptipContent{
    display: flex;
    width: 100%;
}
  .ivu-layout {
    background: transparent;
    height: 100%;
  }
  .header{
    height: 40px;
    line-height: 40px;
    background: transparent;
  }
  ::v-deep .ivu-breadcrumb{
    font-size: 16px;
  }
  ::v-deep .ivu-breadcrumb a{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb-item-separator{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb>span:last-child{
    color: #0077EE;
  }
  ::v-deep .ivu-form .ivu-form-item-label{
   color: #0077EE;
 }
 
.content {
    display: flex;
    justify-content: center;
    /* background-color: red; */
    height: calc(100% - 40px);
}
 ::v-deep .ivu-input{
   background-color: transparent;
   color: #0077EE;
   border-color: #0077EE;
 }
 ::v-deep .ivu-input-number{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-input-number-input{
   background-color: transparent;
   color: #0077EE;
 }
 ::v-deep .ivu-select-selection{
   background-color: transparent;
   border-color: #0077EE;
   color: #0077EE;
 }
 ::v-deep .ivu-spin-fix{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-table-wrapper>.ivu-spin-fix{
   border: none;
 }
</style>
